import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { loadingState, modelsState, pagingState, selectedMarketsState } from './state-store'
import { useEffect, useRef } from 'react'
import { getModelsByMarket } from '../models/model-service'

export const useModels = () => {

  const setLoading = useSetRecoilState(loadingState)

  const selectedMarkets = useRecoilValue(selectedMarketsState)

  const previouslySelectedMarkets = useRef([])

  const setModels = useSetRecoilState(modelsState)

  const resetPaging = useResetRecoilState(pagingState)

  useEffect(() => {
    setLoading(true)
    resetPaging()
    const addedMarkets = selectedMarkets.filter(market => !previouslySelectedMarkets.current.includes(market))
    previouslySelectedMarkets.current = selectedMarkets
    const fetchModels = async () => {
      const newMarketModels = await Promise.all(
        addedMarkets.map(async market => ({ [market]: await getModelsByMarket(market) }))
      )
      setModels(prevModels =>
        Object.assign({},
          Object.fromEntries(selectedMarkets.map(m => [ m, prevModels[m] ])),
          ...newMarketModels)
      )
    }
    fetchModels()
      .then(() => setLoading(false))
  }, [
    selectedMarkets,
    previouslySelectedMarkets,
    setModels,
    setLoading,
    resetPaging,
  ])

}
