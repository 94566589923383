import { atom, atomFamily } from 'recoil'
import { fetchBffMarkets } from './BffClient'
import { localstorageRepo } from './localstorageRepo'

const namespace = key => `app.global.${key}`

export const allMarketsState = atom({
  key: namespace('allMarketsState'),
  default: [],
  effects: [
    ({ setSelf }) => {
      // Todo 04.03.25: could be moved to `default` if we want to fetch all markets on app load
      fetchBffMarkets("blue")
        .then(markets => markets.sort((x, y) => x.market > y.market ? 1 : -1))
        .then(setSelf)
    },
  ],
})

const preferenceNamespace = key => namespace(`preferences.${key}`)

export const preferencesState = atomFamily({
  key: namespace('preferencesState'),
  default: key => localstorageRepo.get(preferenceNamespace(key)),
  effects: key => [
    ({ onSet }) => {
      onSet(newValue => {
        localstorageRepo.set(preferenceNamespace(key), newValue)
      })
    },
  ],
})
