import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Stage from './Stage'
import HomePage from './HomePage'
import Versions from './Versions'
import VmceDashboard from './Vmce'
import AuthRedirect from "./AuthRedirect"
import { PccdExport } from './PccdExport'
import NewDashboard from "./dashboard/Dashboard"
import { Releases } from './releases'
import "driver.js/dist/driver.css"
import { RecoilRoot } from 'recoil'
import './side-panel-style.css'
import { Profile } from './profile'

function App() {
  return (<div className="App">
    <header className="App-header">
    </header>
    <Router>
      <Routes>
        <Route index element={<HomePage/>}/>
        <Route path="/auth/redirect" element={<AuthRedirect/>}/>
        <Route path="/:stage" element={<Stage/>}>
          <Route index element={<div className="container-fluid"></div>}/>
          <Route path="dashboard" element={<NewDashboard/>}></Route>
          <Route path="i-am-mpi-internal/pccd-export" element={<PccdExport/>}/>
          <Route path="versions" element={<Versions/>}/>
          <Route path="releases" element={<Releases/>}/>
          <Route path="vmce-dashboard" element={<VmceDashboard/>}/>
          {/*<Route path="config-center" element={<ConfigCenter/>}/>*/}
        </Route>
        <Route path="profile" element={<Profile/>}/>
      </Routes>
    </Router>
  </div>)
}

function RecoilApp() {
  return <RecoilRoot>
    <App/>
  </RecoilRoot>
}

export default RecoilApp
