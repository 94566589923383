import { useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useRef } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'

/* eslint-disable react-hooks/exhaustive-deps */ // ignore for react-route known issue
/* eslint-disable react-hooks/rules-of-hooks */ // ignore for mapping over recoil states
export const useUrlQueryParam = urlQueryParamStates => {

  const urlStates = urlQueryParamStates.map((state, index) => {
    const [ value, set ] = useRecoilState(state)
    const reset = useResetRecoilState(state)
    return { index, value, set, reset }
  })

  const [ searchParams, _setSearchParams ] = useSearchParams()

  const setSearchParams = useCallback(_setSearchParams, [])

  const queryParamRef = useRef('')

  useEffect(() => {
    const queryParam = searchParams.get('q') ?? ''
    if (queryParamRef.current === queryParam) {
      return
    }
    const nextStateObject = queryParamToStates(queryParam)
    urlStates.forEach(state => {
      const nextState = nextStateObject?.[state.index]
      return nextState ? state.set(nextState) : state.reset()
    })
  }, [ searchParams ])

  useEffect(() => {
    const isStatesSet = urlStates.some(state => state.value && state.value.length)
    const queryParam = isStatesSet ? statesToQueryParam(urlStates) : ''
    queryParamRef.current = queryParam
    setSearchParams({ q: queryParam })
  }, [ ...urlStates.map(state => state.value), setSearchParams ])

  const statesToQueryParam = state => {
    const queryObject = Object.fromEntries(state.map(state => [ state.index, state.value ]))
    const json = JSON.stringify(queryObject)
    const encodedQuery = encodeURIComponent(json)
    return btoa(encodedQuery)
  }

  const queryParamToStates = json => {
    try {
      return JSON.parse(decodeURIComponent(atob(json)))
    } catch (ignore) {
      return null
    }
  }
}
