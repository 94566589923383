import { getIdToken, login } from './PPNCognitoService'
import axios from 'axios'

const httpRequest = config =>
  axios.request(config)
    .catch(async error => {
      if (error.response.status === 401) {
        await login()
      } else {
        throw error
      }
    })

const queryDdbPerPage = async (request, continuationToken) =>
  httpRequest({ ...request, params: { continuationToken } })
    .then(response => response.data)

const queryDdb = async request => {
  let continuationToken = null
  let results = []
  do {
    const response = await queryDdbPerPage(request, continuationToken)
    results = results.concat(response.Items)
    continuationToken = response.ContinuationToken
  } while (continuationToken)
  return results
}

export async function queryModels(stage, modelType) {
  const request = {
    method: 'get',
    url: `/api/models/${modelType}`,
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    },
  }
  return queryDdb(request)
}

export async function queryMarketModels(stage, market) {
  const request = {
    method: 'get',
    url: `/api/markets/${market}/models`,
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    },
  }
  return queryDdb(request)
  // .then(items => items.slice(0, 3))
}
