import React from "react"
import ModelsTable from "./ModelsTable"
import Paper from '@mui/material/Paper'
import { RangeSelector } from "./filters/RangeSelector"
import { MarketSelector } from '../market-selector'
import {
  availableLanguagesSelector,
  searchKeywordState,
  selectedLanguagesState,
  selectedMarketsState,
  selectedRangesState
} from './state-store'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { ChipList } from '../styled/ChipList'
import { useUrlQueryParam } from '../url-query-state'
import './style.css'

const Dashboard = () => {

  const availableLanguages = useRecoilValue(availableLanguagesSelector)

  const [ selectedLanguages, setSelectedLanguages ] = useRecoilState(selectedLanguagesState)

  const resetSelectedLanguages = useResetRecoilState(selectedLanguagesState)

  const [ selectedMarkets, setSelectedMarkets ] = useRecoilState(selectedMarketsState)

  const resetSelectedRanges = useResetRecoilState(selectedRangesState)

  const resetSearchKeyword = useResetRecoilState(searchKeywordState)

  useUrlQueryParam([
    selectedMarketsState,
    selectedLanguagesState,
    selectedRangesState,
    searchKeywordState,
  ])

  const handleSelectedMarketsChange = selection => {
    resetSelectedLanguages()
    resetSelectedRanges()
    resetSearchKeyword()
    setSelectedMarkets(selection)
  }

  return <div className="d-flex flex-nowrap w-100">
    <div className="side-panel">
      <MarketSelector selection={selectedMarkets}
                      onSelect={handleSelectedMarketsChange}
                      maxSelections={4}
      />
      {
        availableLanguages.length > 0 && <>
          <p className="language-selector-title">Languages</p>
          <ChipList values={selectedLanguages}
                    options={availableLanguages}
                    onChange={setSelectedLanguages}/>
        </>
      }
      <RangeSelector/>
    </div>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <ModelsTable/>
    </Paper>
  </div>
}

export default Dashboard
