export const releases = [
  // {
  //   id: '718-switch',
  //   name: '718 Switch',
  //   date: "Nov 24",
  //   models: [
  //     [ '982120', '982121' ],
  //     [ '982320', '982321' ],
  //     [ '982110', '982111' ],
  //     [ '982310', '982311' ],
  //     [ '982130', '982131' ],
  //     [ '982330', '982331' ],
  //     [ '982160', '982161' ],
  //     [ '982360', '982361' ],
  //     [ '982850', '982851' ],
  //     [ '982890', '982891' ],
  //   ],
  // },
  {
    id: '911-gt3-rs',
    name: '911 GT3 RS',
    date: "Mar 25",
    models: [
      [ '992850-2025' ]
    ],
  },
  // {
  //   id: '991-gt-2025',
  //   name: '911 GT',
  //   date: "Oct 24",
  //   models: [
  //     [ "992812-2025" ],
  //     [ "992822-2025" ],
  //   ],
  // },
  // {
  //   id: '991-turbo-2025',
  //   name: '911 Turbo 50Y',
  //   date: "Aug 24",
  //   models: [
  //     [ "992470-2025" ],
  //   ],
  // },
  // {
  //   id: 'cayenne-2025',
  //   name: 'Cayenne',
  //   date: "Sep 24",
  //   models: [
  //     [ "9YBAI1-2025" ],
  //     [ "9YBAV1-2025" ],
  //     [ "9YBBS1-2025" ],
  //     [ "9YBBJ1-2025" ],
  //     [ "9YBBN1-2025" ],
  //     [ "9YBCT1-2025" ],
  //     [ "9YBCZ1-2025" ],
  //     [ "9YBCP1-2025" ],
  //     [ "9YAAI1-2025" ],
  //     [ "9YAAV1-2025" ],
  //     [ "9YABS1-2025" ],
  //     [ "9YABJ1-2025" ],
  //     [ "9YABN1-2025" ],
  //     [ "9YACT1-2025" ],
  //   ],
  // },
  // {
  //   id: 'panamera-2025',
  //   name: 'Panamera',
  //   date: "Jun 24",
  //   models: [
  //     [ "YAADG1-2025" ],
  //     [ "YAAFH1-2025" ],
  //     [ "YABFH1-2025" ],
  //   ],
  // },
  // {
  //   id: '911-2025',
  //   name: '911',
  //   date: "Apr 24",
  //   models: [
  //     [ "9921B2-2025" ],
  //     [ "9923B2-2025" ],
  //     [ "992142-2025" ],
  //     [ "992342-2025" ],
  //     [ "992442-2025" ],
  //     [ "992542-2025" ],
  //     [ "992642-2025" ],
  //     [ "992830-2025" ],
  //   ],
  // },
  // {
  //   id: 'taycan-2025',
  //   name: 'Taycan 2025',
  //   date: "Feb 24",
  //   models: [
  //     [ "Y1AAI1-2025" ],
  //     [ "Y1AFP1-2025" ],
  //     [ "Y1AFT1-2025" ],
  //     [ "Y1ADJ1-2025" ],
  //     [ "Y1AFL1-2025" ],
  //     [ "Y1AFM1-2025" ],
  //     [ "Y1BBN1-2025" ],
  //     [ "Y1BDJ1-2025" ],
  //     [ "Y1BFL1-2025" ],
  //     [ "Y1BFM1-2025" ],
  //     [ "Y1CAI1-2025" ],
  //     [ "Y1CDJ1-2025" ],
  //     [ "Y1CFL1-2025" ],
  //     [ "Y1CFM1-2025" ],
  //   ],
  // },
  // {
  //   id: 'macan-2025',
  //   name: 'Macan 2025',
  //   date: "Feb 24",
  //   models: [
  //     [ "XABBB1-2025" ],
  //     [ "XABFD1-2025" ],
  //     [ "XABAA1-2025" ],
  //     [ "XABDC1-2025" ],
  //   ],
  // },
  {
    id: 'taycan-year-switch-2026',
    name: 'Taycan Change T',
    date: "Mar 25",
    models: [
      [ "Y1AAI1-2026" ],
      [ "Y1ADJ1-2026" ],
      [ "Y1AFL1-2026" ],
      [ "Y1AFM1-2026" ],
      [ "Y1BBN1-2026" ],
      [ "Y1BDJ1-2026" ],
      [ "Y1BFL1-2026" ],
      [ "Y1BFM1-2026" ],
      [ "Y1CAI1-2026" ],
      [ "Y1CDJ1-2026" ],
      [ "Y1CFL1-2026" ],
      [ "Y1CFM1-2026" ],
      [ "Y1AFP1-2026" ],
      [ "Y1AFT1-2026" ],
      [ "Y1ABN1-2026" ],
      [ "Y1ADK1-2026" ],
      [ "Y1CDK1-2026" ],
    ],
  },
  {
    id: '911-year-switch-2026',
    name: '911 Change T',
    date: "Mar 25",
    models: [
      [ "9921B2-2026" ],
      [ "9923B2-2026" ],
      [ "992142-2026" ],
      [ "992342-2026" ],
      [ "992442-2026" ],
      [ "992642-2026" ],
      [ "992542-2026" ],
      [ "992812-2026" ],
      [ "992822-2026" ],
      [ "992182-2026" ],
      [ "992382-2026" ],
      [ "9921S2-2026" ],
      [ "9923S2-2026" ],
    ],
  },
  {
    id: 'cayenne-year-switch-2026',
    name: 'Cayenne Change T',
    date: "Mar 25",
    models: [
      [ "9YABN1-2026" ],
      [ "9YBBN1-2026" ],
      [ "9YACT1-2026" ],
      [ "9YBCT1-2026" ],
      [ "9YBCZ1-2026" ],
      [ "9YAAI1-2026" ],
      [ "9YBAI1-2026" ],
      [ "9YAAV1-2026" ],
      [ "9YBAV1-2026" ],
      [ "9YABJ1-2026" ],
      [ "9YBBJ1-2026" ],
      [ "9YBCP1-2026" ],
      [ "9YAAR1-2026" ],
      [ "9YBAR1-2026" ],
      [ "9YAMI1-2026" ],
      [ "9YABS1-2026" ],
      [ "9YBBS1-2026" ],
    ],
  },
  {
    id: 'panamera-year-switch-2026',
    name: 'Panamera Change T',
    date: "Mar 25",
    models: [
      [ "YAAAA1-2026" ],
      [ "YABAA1-2026" ],
      [ "YAABA1-2026" ],
      [ "YABBA1-2026" ],
      [ "YAAFF1-2026" ],
      [ "YABFF1-2026" ],
      [ "YAABE1-2026" ],
      [ "YABBE1-2026" ],
      [ "YAADZ1-2026" ],
      [ "YABDZ1-2026" ],
      [ "YAADG1-2026" ],
      [ "YAAFH1-2026" ],
      [ "YABFH1-2026" ],
    ],
  },
]
