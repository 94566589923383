import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './HomePage.css'
import { Link, Outlet } from 'react-router-dom'
import { env } from './env'
import { Options, usePreference } from './profile/preferences'

const stages = env.stages
const HomePage = ({ stage = stages[0].name }) => {

  const stageDisplay = stages.find(s => s.name === stage).display

  const [ showEnvButton ] = usePreference(Options.SHOW_ENV_BUTTON)

  return <>
    <div className="p-3 border-bottom container-fluid menu-bar">
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <Link to={`/${stage}`}
              className="fs-4 d-flex align-items-center col-1 mb-lg-0 link-body-emphasis text-decoration-none">
          MPI Ops
        </Link>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/dashboard`} className="nav-link px-2 link-body-emphasis">📋 Dashboard</Link>
          </li>
        </ul>
        <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">
          <li>
            <Link to={`/${stage}/releases`} className="nav-link px-2 link-body-emphasis">🚀 Releases</Link>
          </li>
        </ul>
        {/*<ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">*/}
        {/*  <li>*/}
        {/*    <Link to={`/${stage}/config-center`} className="nav-link px-2 link-body-emphasis">🔀 Configurations</Link>*/}
        {/*  </li>*/}
        {/*</ul>*/}

        {/*<ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0">*/}
        {/*  <li>*/}
        {/*    <Link to={`/${stage}/versions`} className="nav-link px-2 link-body-emphasis">Versions</Link>*/}
        {/*  </li>*/}
        {/*</ul>*/}
        {env.featureToggle.vmceOn &&
          <ul className="nav col-12 col-lg-auto me-lg mb-2 justify-content-center mb-md-0 ps-5">
            <li>
              <Link to={`/${stage}/vmce-dashboard`} className="nav-link px-2 link-body-emphasis">VMCE</Link>
            </li>
          </ul>
        }
        <ul className="me-auto"></ul>
        {showEnvButton ? <div className="dropdown col-2">
          <button className="btn btn-primary rounded-pill dropdown-toggle stage-button" data-bs-toggle="dropdown">
            {stageDisplay}
          </button>
          <ul className="dropdown-menu text-small">
            {
              stages
                .map(s => <li key={s.name}><Link className="dropdown-item" to={`/${s.name}`}>{s.display}</Link></li>)
            }
          </ul>
        </div> : <Link to={'https://forms.office.com/e/6xmWP3QYKV'} target="_blank" className="blink">🎁 Give Us Feedback 🎁</Link>
        }      </div>
    </div>
    <div className="feature-component">
      <Outlet/>
    </div>
  </>
}
export default HomePage
