import Paper from '@mui/material/Paper'
import React from 'react'
import { ReleaseTable } from './ReleaseTable'
import { SidePanel } from './SidePanel'
import { useUrlQueryParam } from '../url-query-state'
import { modelSelectionState, onlyUnreleasedState, searchKeywordState } from './state-store'

export const Releases = () => {

  useUrlQueryParam([
    searchKeywordState,
    onlyUnreleasedState,
    modelSelectionState,
  ])

  return (
    <div className="d-flex flex-nowrap w-100">
      <div className="side-panel">
        <SidePanel/>
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden', textAlign: 'left' }}>
        <ReleaseTable/>
      </Paper>
    </div>
  )
}
