import { Autocomplete } from '@mui/joy'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { allMarketsState } from '../state-store'
import './style.css'

export const MarketSelector = ({ selection, onSelect, maxSelections }) => {

  const allMarkets = useRecoilValue(allMarketsState)

  const [ optionListOpen, setOptionListOpen ] = useState(false)

  const maxSelectionReached = selection.length >= maxSelections

  const selectMarkets = (event, newValue) => {
    onSelect([ ...new Set(newValue) ])
    if (newValue.length >= maxSelections) {
      setOptionListOpen(false)
    }
  }

  const openOptionList = () => setOptionListOpen(!maxSelectionReached)

  const marketSelectionFootnote = maxSelections === 1 ? 'Select one market' : `Select up to ${maxSelections} markets`

  return (
    <div className="market-selector">
      <p className="market-selector-title">Markets</p>
      <Autocomplete
        multiple size="sm"
        id="market-selector"
        value={[ ...selection ]}
        options={allMarkets.map(m => m.market)}
        onChange={selectMarkets}
        open={optionListOpen}
        onOpen={openOptionList}
        onClose={() => setOptionListOpen(false)}
        disableCloseOnSelect
        slotProps={{
          input: {
            hidden: maxSelectionReached,
          },
        }}
      />
      <p className="market-selector-footnote">{marketSelectionFootnote}</p>
    </div>
  )
}
