import { atom } from 'recoil'
import { localstorageRepo } from '../localstorageRepo'

const atomNamespace = key => `app.releases.${key}`

export const modelSelectionState = atom({
  key: atomNamespace('modelSelectionState'),
  default: [],
})

export const releaseBatchSelectionState = atom({
  key: atomNamespace('releaseBatchSelectionState'),
  default: undefined,
})

export const loadingState = atom({
  key: atomNamespace('loadingState'),
  default: false,
})

export const onlyUnreleasedState = atom({
  key: atomNamespace('onlyUnreleasedState'),
  default: localstorageRepo.get('app.releases.onlyUnreleased') === 'true' ?? false,
})

export const searchKeywordState = atom({
  key: atomNamespace('ReleaseTableSearchKeywordState'),
  default: '',
})

export const modelsState = atom({
  key: atomNamespace('modelsState'),
  default: [],
})
